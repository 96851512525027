import HyRequest, { hyRequest1 } from '@/service';

// 设备信息列表
export function getDeviceInfoList(params: {
  pageIndex: number;
  pageSize: number;
  t?: any;
}): Promise<any> {
  return HyRequest.post({
    url: '/deviceEaApp/findDeviceList',
    data: {
      ...params
    }
  });
}
//设备类型
export function getDeviceType(dictType: string): Promise<any> {
  return HyRequest.get({
    url: `/system/dict/data/selectDictDataByType/${dictType}`
  });
}
// 设备详情
export function getDeviceDetail(params: {
  factoryId?: string;
  deviceNum?: string;
}): Promise<any> {
  return HyRequest.get({
    url: '/deviceEaApp/qryDeviceInfoByDeviceNum',
    params: {
      ...params
    }
  });
}
// 获取设备详情的巡检
export function getDevicePatrolScheme(params: {
  t: number;
  pageIndex: number;
  pageSize: number;
}): Promise<any> {
  return HyRequest.post({
    url: '/eaInspectionItemInfoApp/findTaskItemInfoCheck',
    data: {
      ...params
    }
  });
}
// 获取巡保
export function getDevicePatrolGuard(params: {
  t: number;
  pageIndex: number;
  pageSize: number;
}): Promise<any> {
  return HyRequest.post({
    url: '/eaInspectionItemInfoApp/findTaskItemInfoMain',
    data: {
      ...params
    }
  });
}

// 获取任务历史
export function getDeviceHistory(params: {
  t?: { beginTime?: string; endTime?: string; deviceId: number };
  pageIndex: number;
  pageSize: number;
}): Promise<any> {
  return HyRequest.post({
    url: '/h5/inspectionHistory/selectInspectionHistoryByPage',
    data: {
      ...params
    }
  });
}
