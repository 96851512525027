import { ref, Ref, computed, ComputedRef, watch } from 'vue';
import { IFacDataItem } from '@/service/production/equipmentLive/type';
import {
  getDeviceGroupMyFocusDetail,
  getFac
} from '@/service/production/equipmentLive';
import { useStore } from '@/store';

export default function useGuliaoList(): [
  Ref<IFacDataItem[]>,
  Ref<IFacDataItem[]>,
  Ref<string>,
  Ref<boolean>,
  () => Promise<void>
] {
  const store = useStore();
  const list1 = ref<IFacDataItem[]>([]);
  const list2 = ref<IFacDataItem[]>([]);
  const showLoading = ref(false);
  const type = ref('');
  const groupID = computed<string | undefined>(() => {
    return store.getters['production/deviceGroupId'];
  });

  // 获取主机汇总数据
  const getFacAsync = async () => {
    try {
      list1.value = []; // 关注或设备列表
      list2.value = []; // 温度列表
      showLoading.value = true;

      // 如果groupID不存在则 获取关注的设备列表， 存在则获取对应groupID的设备列表或温度列表数据
      if (groupID.value) {
        const res = await getFac({ groupNo: groupID.value });
        type.value = res.data.type ?? '';
        if (res.data.type == 'temp') {
          // 如果是温度
          list2.value = [...res.data.list];
        } else {
          // 不是温度，则是设备列表
          list1.value = [...res.data.list];
        }

        store.commit('production/updateDeviceGroup', res.data.list);
      } else {
        type.value = '';
        // 当前菜单项索引为0
        store.commit('production/updateSiderbarIndex', 0);
        // 获取关注的设备列表
        const res1 = await getDeviceGroupMyFocusDetail();
        list1.value = [...res1.data.list];
        store.commit('production/updateDeviceGroup', res1.data.list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      showLoading.value = false;
    }
  };

  // 监听左侧菜单发生改变，左侧菜单切换时，groupID会发生改变，需要去请求对应菜单的数据
  watch(groupID, () => {
    console.log('grounpId发生了改变', groupID);
    getFacAsync();
  });

  return [list1, list2, type, showLoading, getFacAsync];
}
