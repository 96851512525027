import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import { IStockTrendParams, IStockTrend } from './type'

enum DashboardApi {
  getStockTrend = '/stockdata/historyStoneValue',
  getMonitorAccessToken = '/monitor/getMonitorAccessToken'
}

export function getStockTrend(
  params: IStockTrendParams
): Promise<IRequestResult<IStockTrend>> {
  return hyRequest.post<IRequestResult<IStockTrend>>({
    url: DashboardApi.getStockTrend,
    data: params
  })
}
export function getMonitorAccessToken(): Promise<IRequestResult<string>> {
  return hyRequest.post<IRequestResult<string>>({
    url: DashboardApi.getMonitorAccessToken
  })
}
