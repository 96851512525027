import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'HostSummaryItem',
  components: {},
  emits: ['btn-click'],
  props: {
    item: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: '0'
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var emptyStringFormat = useEmptyStringFormat();
    var formatDate = function formatDate(date) {
      return dayjs(new Date(date)).format('MM月DD日 HH:mm');
    };
    var router = useRouter();
    var btnClick = function btnClick() {
      if (props.item.closeNum === '0') return;
      router.push({
        name: 'ShutdownReason',
        params: {
          areaId: props.item.areaId,
          startTime: props.item.openTime,
          endTime: props.item.closeTime,
          deviceKey: props.item.deviceKey,
          deviceName: props.item.deviceName,
          title: props.title
        }
      });
    };
    return {
      btnClick: btnClick,
      emptyStringFormat: emptyStringFormat,
      formatDate: formatDate
    };
  }
});