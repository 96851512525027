import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f574077e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "custom-log"
};
var _hoisted_2 = {
  class: "hostSummary"
};
var _hoisted_3 = {
  class: "hostSummary"
};
var _hoisted_4 = {
  class: "num"
};
var _hoisted_5 = {
  class: "desc"
};
var _hoisted_6 = {
  class: "desc"
};
var _hoisted_7 = {
  class: "num"
};
var _hoisted_8 = {
  class: "desc"
};
var _hoisted_9 = {
  class: "num"
};
var _hoisted_10 = {
  class: "desc"
};
var _hoisted_11 = {
  key: 0,
  class: "hostSummary"
};
var _hoisted_12 = {
  class: "num"
};
var _hoisted_13 = {
  class: "desc"
};
var _hoisted_14 = {
  key: 0,
  class: "listBox mt-2"
};
var _hoisted_15 = {
  class: "title text-ellipsis"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_date_time_range_select = _resolveComponent("c-date-time-range-select");
  var _component_c_title = _resolveComponent("c-title");
  var _component_yield_sum = _resolveComponent("yield-sum");
  var _component_van_grid_item = _resolveComponent("van-grid-item");
  var _component_van_grid = _resolveComponent("van-grid");
  var _component_open_close_item = _resolveComponent("open-close-item");
  var _component_c_no_data = _resolveComponent("c-no-data");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_c_date_time_range_select, {
    class: "my-2",
    onConfirmDate: _ctx.confirmDatetime
  }, null, 8, ["onConfirmDate"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_c_title, {
    title: "主机生产统计"
  }), _createElementVNode("div", {
    class: _normalizeClass(["sum", {
      open: _ctx.mainDataTotal.status === '1',
      close: _ctx.mainDataTotal.status === '0'
    }])
  }, [_createVNode(_component_yield_sum, {
    isJump: false,
    data: _ctx.mainDataTotal
  }, null, 8, ["data"])], 2)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_c_title, {
    title: "砂石骨料生产统计"
  }), _createElementVNode("div", {
    class: _normalizeClass(["sum", {
      open: _ctx.sandData.status === '1',
      close: _ctx.sandData.status === '0'
    }])
  }, [_createVNode(_component_yield_sum, {
    isJump: false,
    data: _ctx.sandDataTotal
  }, null, 8, ["data"])], 2), _createVNode(_component_van_grid, {
    gutter: 10,
    "column-num": 3,
    class: "mb-2 grid"
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sandListData, function (item) {
        return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.factoryID != 187 || item.material_name != '收尘粉/吨' ? (_openBlock(), _createBlock(_component_van_grid_item, {
          key: item.material_name
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.emptyStringFormat(item.pro_num)), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.emptyStringFormat(item.material_name)), 1), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.emptyStringFormat(item.ratio)), 1)];
          }),
          _: 2
        }, 1024)) : _createCommentVNode("", true)], 64);
      }), 256))];
    }),
    _: 1
  }), _createVNode(_component_c_title, {
    title: "耗电统计"
  }), _createVNode(_component_van_grid, {
    class: "grid mb-2",
    gutter: 10,
    "column-num": 2
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sandEleTotal, function (item) {
        return _openBlock(), _createBlock(_component_van_grid_item, {
          key: item.desc
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.emptyStringFormat(item.num)), 1), _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.emptyStringFormat(item.desc)), 1)];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }), _ctx.factoryID == 187 ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_c_title, {
    title: "泥饼量"
  }), _createVNode(_component_van_grid, {
    class: "grid mb-2",
    gutter: 10,
    "column-num": 2
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_grid_item, null, {
        default: _withCtx(function () {
          return [_createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.sandData.soil.net_weight), 1), _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.sandData.soil.material_name), 1)];
        }),
        _: 1
      })];
    }),
    _: 1
  })], 64)) : _createCommentVNode("", true)]), _ctx.factoryID != 187 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_c_title, {
    title: "水洗生产统计"
  }), _createElementVNode("div", {
    class: _normalizeClass(["sum", {
      open: _ctx.waterData.status === '1',
      close: _ctx.waterData.status === '0'
    }])
  }, [_createVNode(_component_yield_sum, {
    isJump: false,
    data: _ctx.waterDataTotal
  }, null, 8, ["data"])], 2), _createVNode(_component_c_title, {
    title: "耗电统计"
  }), _createVNode(_component_van_grid, {
    class: "grid mb-2",
    gutter: 10,
    "column-num": 2
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.waterTotal, function (item) {
        return _openBlock(), _createBlock(_component_van_grid_item, {
          key: item.desc
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.emptyStringFormat(item.num)), 1), _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.emptyStringFormat(item.desc)), 1)];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sandMakingList, function (item) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: item.name
    }, [item.name == '水洗线' ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, _toDisplayString(item.name) + "开停机信息", 1), item.list.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(item.list, function (iten) {
      return _openBlock(), _createBlock(_component_open_close_item, {
        key: iten.closeTime,
        item: iten,
        title: item.name,
        type: "1"
      }, null, 8, ["item", "title"]);
    }), 128)) : (_openBlock(), _createBlock(_component_c_no_data, {
      key: 1
    }))])) : _createCommentVNode("", true)], 64);
  }), 128))])) : _createCommentVNode("", true)]);
}