import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import { IDetailListItem } from '../equipmentLive/type'
import {
  IMainData,
  ISandData,
  IChangeShiftDate,
  IClassesUsers,
  IOpenCloseData,
  IProCloseDetail,
  ITimeRange,
  IOpenCloseParams,
  IProCloseDetailParams,
  IUpdateDowntimeReasonParams,
  IProductHourData,
  ITrendParams,
  IDateParams,
  IStoneEle,
  IProDataTrendItem,
  IDeviceAreaListParams,
  IDeviceAreaListItem
} from './type'

enum DashboardApi {
  getChangeShiftDate = '/product/getChangeShiftDate',
  getMainData = '/product/getMainData',
  getSandData = '/product/getSandData',
  getClassesUsers = '/product/getClassesUsers',
  getOpenCloseList = '/product/getOpenCloseList',
  proCloseDetail = '/product/proCloseDetail',
  updateAggregateDetails = '/product/updateAggregateDetailsForLT',
  getProductHourData = '/product/productHourData',
  canUpdateProData = '/sysRoleApp/canUpdateProData',
  getStoneEleByDateType = '/product/getStoneEleByDateType',
  getProDataTrend = '/product/proDataTrend',
  getDeviceAreaList = '/product/getDeviceAreaList'
}

// 获取主机总汇产量数据
export function getMainData(
  data: ITimeRange
): Promise<IRequestResult<IMainData>> {
  return hyRequest.post<IRequestResult<IMainData>>({
    url: DashboardApi.getMainData,
    data
  })
}

// 获取制砂总汇产量数据
export function getSandData(
  data: ITimeRange
): Promise<IRequestResult<ISandData>> {
  return hyRequest.post<IRequestResult<ISandData>>({
    url: DashboardApi.getSandData,
    data
  })
}

// 查询交接班 可选日期
export function getChangeShiftDate(): Promise<
  IRequestResult<IChangeShiftDate>
> {
  return hyRequest.post<IRequestResult<IChangeShiftDate>>({
    url: DashboardApi.getChangeShiftDate
  })
}

// 查询班次人员
export function getClassesUsers(): Promise<IRequestResult<IClassesUsers>> {
  return hyRequest.post<IRequestResult<IClassesUsers>>({
    url: DashboardApi.getClassesUsers
  })
}

// 查询当天开停机列表
export function getOpenCloseList(
  data: IOpenCloseParams
): Promise<IRequestResult<IOpenCloseData>> {
  return hyRequest.post<IRequestResult<IOpenCloseData>>({
    url: DashboardApi.getOpenCloseList,
    data
  })
}

// 查询时间范围内停机详情
export function getProCloseDetail(
  data: IProCloseDetailParams
): Promise<IRequestResult<IProCloseDetail>> {
  return hyRequest.post<IRequestResult<IProCloseDetail>>({
    url: DashboardApi.proCloseDetail,
    data
  })
}

// 骨料日报修改停机原因
export function updateDowntimeReason(
  data: IUpdateDowntimeReasonParams
): Promise<IRequestResult<string>> {
  return hyRequest.post<IRequestResult<string>>({
    url: DashboardApi.updateAggregateDetails,
    data
  })
}

//小时产量
export function getProductHourData(data: {
  dayTime: string
}): Promise<IRequestResult<IProductHourData>> {
  return hyRequest.post<IRequestResult<IProductHourData>>({
    url: DashboardApi.getProductHourData,
    data
  })
}
//查看是否有产量修正页面
export function canUpdateProData(): Promise<IRequestResult<'0' | '1'>> {
  return hyRequest.get<IRequestResult<'0' | '1'>>({
    url: DashboardApi.canUpdateProData
  })
}

//根据时间类型获取骨料产量、耗电量、吨耗电
export function getStoneEleByDateType(
  data: IDateParams
): Promise<IRequestResult<IStoneEle>> {
  return hyRequest.post<IRequestResult<IStoneEle>>({
    url: DashboardApi.getStoneEleByDateType,
    data
  })
}
//产量趋势
export function getProDataTrend(
  data: ITrendParams
): Promise<IRequestResult<{ list: IProDataTrendItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: IProDataTrendItem[] }>>({
    url: DashboardApi.getProDataTrend,
    data
  })
}

//查询产线列表
export function getDeviceAreaList(
  data: IDeviceAreaListParams
): Promise<IRequestResult<{ list: IDeviceAreaListItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: IDeviceAreaListItem[] }>>({
    url: DashboardApi.getDeviceAreaList,
    data
  })
}

//获取主机总汇产量数据
//获取制砂总汇产量数据
//查询当天开停机列表
