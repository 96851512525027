import { ref, Ref } from 'vue'
import {
  ITrendDataItem,
  IProDataParams
} from '@/service/production/ProReportShangTong/type'
import { getCommercialElectricTrend } from '@/service/production/ProReportShangTong'

export default function useProDataTrend(
  params: Ref<IProDataParams>
): [
  Ref<ITrendDataItem[]>,
  Ref<ITrendDataItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const shangTongElectircTrend = ref<ITrendDataItem[]>([])
  const shangTongElectircYearTrend = ref<ITrendDataItem[]>([])
  // 获取主机汇总数据
  const getShangTongElectricTrendAsync = async () => {
    let num = 30

    switch (params.value.type) {
      // 日
      case 0:
        num = 30
        break
      // 周
      case 1:
        num = 30
        break
      // 月
      case 2:
        num = 12
        // num = 30
        break
      // 年
      case 3:
        num = 3
        // num = 12
        break
      // 自定义
      case 4:
        num = 10
        break
    }
    const res = await getCommercialElectricTrend({
      ...params.value,
      rangeType: params.value.type,
      rangeNum: num
    })
    shangTongElectircTrend.value = res.data.list
  }

  const getShangTongElectricYearTrendAsync = async () => {
    const res = await getCommercialElectricTrend({
      type: 3,
      date: params.value.date,
      rangeType: -1,
      rangeNum: 3
    })
    shangTongElectircYearTrend.value = res.data.list
  }
  return [
    shangTongElectircTrend,
    shangTongElectircYearTrend,
    getShangTongElectricTrendAsync,
    getShangTongElectricYearTrendAsync
  ]
}
