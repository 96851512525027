import { ref, Ref } from 'vue'
import { canUpdateProData } from '@/service/production/proReportAggregate'

export default function useHourData(): [Ref<'0' | '1'>, () => Promise<void>] {
  const hasPermission = ref<'0' | '1'>('0')

  // 获取主机汇总数据
  const canUpdateProDataAsync = async () => {
    const res = await canUpdateProData()
    if (res) {
      hasPermission.value = res.data
    }
  }

  return [hasPermission, canUpdateProDataAsync]
}
