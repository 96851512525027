import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  ISaleStatisticsItem,
  IReceiveStatisticsItem,
  IProDataParams,
  IReceiveStatisticsData
} from '@/service/production/ProReportShangTong/type'
import {
  getCommercialSaleStatistics,
  getCommercialReceiveStatistics
} from '@/service/production/ProReportShangTong'

export default function useProData(
  params: Ref<IProDataParams>
): [
  Ref<IReceiveStatisticsData>,
  Ref<ISaleStatisticsItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const receivingData = ref<IReceiveStatisticsData>({
    list: [],
    name: '',
    sumTotal: 0,
    sumWeight: 0
  })
  const saleData = ref<ISaleStatisticsItem[]>([])
  // 获取商砼原材料采购统计
  const getCommercialReceiveStatisticsAsyc = async () => {
    try {
      const res = await getCommercialReceiveStatistics(params.value)
      receivingData.value = res.data
    } catch (err) {
      console.log(err)
    }
  }
  //获取商砼销售统计
  const getCommercialSaleStatisticsAsyc = async () => {
    try {
      const res = await getCommercialSaleStatistics(params.value)
      saleData.value = res.data.list
    } catch (err) {
      console.log(err)
    }
  }

  return [
    receivingData,
    saleData,
    getCommercialReceiveStatisticsAsyc,
    getCommercialSaleStatisticsAsyc
  ]
}
