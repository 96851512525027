import {
  editCollectionInfo,
  getCollectionInfo
} from '@/service/operate/comprehensiveDaily'
import {
  IAttendanceParams,
  ICollectionInfo
} from '@/service/operate/comprehensiveDaily/type'
import { computed, ComputedRef, ref, Ref } from 'vue'

export default function useCollectionCorrection(
  params: Ref<IAttendanceParams>
): [
  Ref<ICollectionInfo>,
  () => Promise<void>,
  ComputedRef<{ title: string; value: number }[]>
] {
  const collectionInfo: Ref<ICollectionInfo> = ref({
    factory_id: 0, // 厂区主键
    collect_date: '', // 收款日期
    update_time: '',
    deleted: 0,
    create_time: '',
    concrete_collect: 0, // 商砼收款（元）
    stone_collect: 0, // 骨料收款（元）
    id: 0,
    mortar_collect: 0 // 砂浆收款（元）
  })

  const collectionList = computed(() => {
    return [
      {
        title: '骨料',
        value: collectionInfo.value.stone_collect
      },
      {
        title: '砂浆',
        value: collectionInfo.value.mortar_collect
      },
      {
        title: '商砼',
        value: collectionInfo.value.concrete_collect
      }
    ]
  })

  const getCollectionInfoAsync = async () => {
    const res = await getCollectionInfo(params.value)
    collectionInfo.value = res.data
  }

  return [collectionInfo, getCollectionInfoAsync, collectionList]
}
