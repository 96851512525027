import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  IProData,
  IProDataParams,
  IReceivingListItem
} from '@/service/production/ProReportShangTong/type'
import { getCommercialProData } from '@/service/production/ProReportShangTong'

export default function useProData(date: Ref<IProDataParams>): [
  Ref<IProData>,
  ComputedRef<{
    pieData: {
      name: string
      value: number
    }[]
    unit: string
    customTooltip: boolean
    tooltipFormatter: (params: any) => string
  }>,
  () => Promise<void>
] {
  const shangTongData = ref<IProData>({
    totalSettleValue: 0,
    totalAveragePrice: 0,
    receivingList: [],
    totalMoney: 0,
    totalSingular: 0
  })
  const list = ref<IReceivingListItem[]>([])
  // 获取制砂总汇
  const getCommercialProDataAsyc = async () => {
    const res = await getCommercialProData(date.value)
    shangTongData.value = res.data
    list.value = res.data.receivingList
  }
  const receiveData = computed(() => {
    return {
      pieData: list.value
        .map((item) => {
          return {
            name: item.stone_name,
            value: item.release_nums
          }
        })
        .filter((item) => item.value),
      unit: '吨',
      customTooltip: true,
      tooltipFormatter: (params: any) => {
        return `
          <div >
            <div class="flex align-start">
              <span class="mt-1 mr-1">${params.marker}</span>
              <div>
                <span style="font-size: 12px;">${params.name}</span>
                <div>
                 <span style="font-size: 12px;">${params.value}吨</span>
                </div>
              </div>
            </div>
          </div>
          `
      }
    }
  })
  return [shangTongData, receiveData, getCommercialProDataAsyc]
}
