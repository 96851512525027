import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'CDateSelect',
  emits: ['confirm-current'],
  props: {
    backgroundColor: {
      type: String,
      default: '#F6F7F8'
    },
    columns: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var show = ref(false);
    var defaultIndex = ref(0);
    var title = ref('');
    var showPicker = function showPicker() {
      show.value = true;
    };
    var onConfirm = function onConfirm(value, index) {
      title.value = value.text;
      defaultIndex.value = index;
      show.value = false;
      emit('confirm-current', value);
    };
    var onCancel = function onCancel() {
      return show.value = false;
    };
    var preClick = function preClick() {
      if (defaultIndex.value === 0) {
        _Toast('当前已经是第一个选项了');
        return;
      }
      title.value = props.columns[--defaultIndex.value].text;
      emit('confirm-current', props.columns[defaultIndex.value]);
    };
    var nextClick = function nextClick() {
      if (defaultIndex.value === props.columns.length - 1) {
        _Toast('当前已经是最后一个选项了');
        return;
      }
      title.value = props.columns[++defaultIndex.value].text;
      emit('confirm-current', props.columns[defaultIndex.value]);
    };
    watch(function () {
      return props.columns;
    }, function (newVal) {
      title.value = newVal[0].text;
    });
    return {
      onCancel: onCancel,
      onConfirm: onConfirm,
      show: show,
      showPicker: showPicker,
      preClick: preClick,
      nextClick: nextClick,
      title: title,
      defaultIndex: defaultIndex
    };
  }
});