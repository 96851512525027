import { ref, ComputedRef, computed } from 'vue'
import { IProductHourDataItem } from '@/service/production/proReportAggregate/type'
import { getProductHourData } from '@/service/production/proReportAggregate'

export default function useHourData(
  params: ComputedRef<{
    dayTime: string
  }>
): [
  ComputedRef<{
    xLabels: string[]
    lineData: number[]
    legendData: string[]
    color: string
    height: string
    grid: {
      left: number
      top: number
      bottom: number
      right: number
    }
    labelColor: string
    splitLineColor: string[]
    splitLineType: string
    showBackground: boolean
  }>,
  () => Promise<void>
] {
  const hourData = ref<IProductHourDataItem[]>([])

  // 获取主机汇总数据
  const getProductHourDataAsync = async () => {
    const res = await getProductHourData(params.value)
    if (res) {
      hourData.value = res.data.list
    }
  }
  const hourDataTotal = computed(() => {
    const lineData: number[] = hourData.value.map((item) => item.net_weight)
    const xLabels: string[] = hourData.value.map((item) => item.hour_time)
    const legendData = ['时产']
    return {
      xLabels,
      lineData,
      legendData,
      legendShow: false,
      color: 'rgba(255,255,255,0.6)',
      height: '150px',
      grid: {
        left: 50,
        top: 10,
        bottom: 30,
        right: 20
      },
      labelColor: 'rgba(255, 255, 255, 0.5)',
      splitLineColor: ['rgba(255, 255, 255, 0.2)'],
      splitLineType: 'dashed',
      showBackground: false,
      customTooltip: true,
      formatter: (
        params: {
          axisValue: string
          marker: string
          value: string
          seriesName: string
        }[]
      ) => {
        let res = `
            <div >
              <div class="flex align-center">
                <span class="ml-2">${params[0].axisValue}</span>
              </div>
            `
        params.forEach((item) => {
          res += `
              <div >
                ${item.marker}
                <span style="font-size: 12px;">${item.seriesName}</span>
                <span style="font-weight:bold;font-size: 12px;">${item.value}吨</span>
              </div>
            `
        })
        res += '</div>'
        return res
      }
    }
  })
  return [hourDataTotal, getProductHourDataAsync]
}
