import hyRequest, { hyRequest1, hyRequest2 } from '@/service'
import { IRequestResult, IRequestResult2 } from '@/service/types'
import {
  ISales,
  IProduction,
  IReceiving,
  IAccount,
  ILineStatusData,
  IAttendance,
  IAttendanceParams,
  ISaleSummary,
  IReceiveSumary,
  IProSumary,
  IProSumaryRes,
  EPermission,
  ICollectionInfo,
  TShowBlueSkyBudget,
  ISaleParams,
  ISaleData,
  IPurchaseSumary,
  ILineTimeItem
} from './type'

enum DashboardApi {
  getSales = '/ws/report/sales/findDaySales',
  getProduction = '/ws/report/sales/findDayProduction',
  getReceiving = '/ws/report/receivingDay',
  getAccount = '/report/report/userFinanceAccountByType',
  getLineStatus = '/product/lineStatus',
  getLineTimes = '/blueSkrLed/lineTimes',
  getAttendanceStatistics = '/oa/attendanceStatistics',
  getSaleSummary = '/saleData/saleSummary',
  getCommercialSale = '/saleData/commercialSaleData',
  getMortarSale = '/saleData/mortarSaleData',
  getAggregateSale = '/saleData/aggregateSaleData',
  getPurchaseSummary = '/purchaseData/purchaseSummary',
  getReceiveSummary = '/saleData/receiveSummary',
  getProSumary = '/product/productDataSummary',
  getMoneyEditPermission = '/sysRoleApp/canUpdateCollectData', // 收款修改权限
  getCollectionInfo = '/collectManage/queryCollectManageByDate', // 获取收款信息
  editCollectionInfo = '/collectManage/saveCollectManage', // 修改收款信息
  canShowBlueSkyBudget = '/sysRoleApp/canShowBlueSkyBudget'
}

// 获取骨料产量、耗电量、吨耗电
export function getSales(params: {
  startTime: string
}): Promise<IRequestResult2<ISales>> {
  return hyRequest2.get<IRequestResult2<ISales>>({
    url: DashboardApi.getSales,
    params
  })
}

// 产量汇总
export function getProSum(params: {
  date: string
}): Promise<IRequestResult<IProSumaryRes>> {
  return hyRequest.post<IRequestResult<IProSumaryRes>>({
    url: DashboardApi.getProSumary,
    data: params
  })
}

//
export function getProduction(params: {
  startTime: string
}): Promise<IRequestResult2<IProduction>> {
  return hyRequest2.get<IRequestResult2<IProduction>>({
    url: DashboardApi.getProduction,
    params
  })
}
export function getReceiving(params: {
  startTime: string
}): Promise<IRequestResult2<IReceiving>> {
  return hyRequest2.get<IRequestResult2<IReceiving>>({
    url: DashboardApi.getReceiving,
    params
  })
}
export function getAccount(params: {
  search_day: string
  search_month: string
}): Promise<IRequestResult<IAccount>> {
  return hyRequest1.get<IRequestResult<IAccount>>({
    url: DashboardApi.getAccount,
    params
  })
}

//查询产线状态
export function getLineStatus(): Promise<IRequestResult<ILineStatusData>> {
  return hyRequest.post<IRequestResult<ILineStatusData>>({
    url: DashboardApi.getLineStatus
  })
}
export function getLineTimes(): Promise<
  IRequestResult<{ list: ILineTimeItem[] }>
> {
  return hyRequest.post<IRequestResult<{ list: ILineTimeItem[] }>>({
    url: DashboardApi.getLineTimes
  })
}

//考勤统计
export function getAttendanceStatistics(
  params: IAttendanceParams
): Promise<IRequestResult<IAttendance>> {
  return hyRequest.post<IRequestResult<IAttendance>>({
    url: DashboardApi.getAttendanceStatistics,
    data: params
  })
}

//销售汇总数据

export function getSaleSummary(
  params: IAttendanceParams
): Promise<IRequestResult<ISaleSummary>> {
  return hyRequest.post<IRequestResult<ISaleSummary>>({
    url: DashboardApi.getSaleSummary,
    data: params
  })
}

//骨料销售数据
export function getAggregateSale(
  params: ISaleParams
): Promise<IRequestResult<ISaleData>> {
  return hyRequest.post<IRequestResult<ISaleData>>({
    url: DashboardApi.getAggregateSale,
    data: params
  })
}
//砂浆销售数据
export function getMortarSale(
  params: ISaleParams
): Promise<IRequestResult<ISaleData>> {
  return hyRequest.post<IRequestResult<ISaleData>>({
    url: DashboardApi.getMortarSale,
    data: params
  })
}
//商砼销售数据
export function getCommercialSale(
  params: ISaleParams
): Promise<IRequestResult<ISaleData>> {
  return hyRequest.post<IRequestResult<ISaleData>>({
    url: DashboardApi.getCommercialSale,
    data: params
  })
}

//采购汇总数据
export function getPurchaseSummary(
  params: IAttendanceParams
): Promise<IRequestResult<IPurchaseSumary>> {
  return hyRequest.post<IRequestResult<IPurchaseSumary>>({
    url: DashboardApi.getPurchaseSummary,
    data: params
  })
}

//领用汇总数据
export function getReceiveSummary(
  params: IAttendanceParams
): Promise<IRequestResult<IReceiveSumary>> {
  return hyRequest.post<IRequestResult<IReceiveSumary>>({
    url: DashboardApi.getReceiveSummary,
    data: params
  })
}

// 获取是否有修改收款权限
export function getMoneyEditPermission(): Promise<IRequestResult<EPermission>> {
  return hyRequest.post({
    url: DashboardApi.getMoneyEditPermission
  })
}

// 获取收款信息
export function getCollectionInfo(
  data: IAttendanceParams
): Promise<IRequestResult<ICollectionInfo>> {
  return hyRequest.post({
    url: DashboardApi.getCollectionInfo,
    data
  })
}

// 修改收款信息
export function editCollectionInfo(
  data: ICollectionInfo
): Promise<IRequestResult> {
  return hyRequest.post({
    url: DashboardApi.editCollectionInfo,
    data
  })
}

// 是否有蓝天预算权限
export function canShowBlueSkyBudget(): Promise<
  IRequestResult<TShowBlueSkyBudget>
> {
  return hyRequest.post({
    url: DashboardApi.canShowBlueSkyBudget
  })
}
