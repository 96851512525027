import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  findProductDayData,
  updateProductData
} from '@/service/production/yieldCorrection'
import {
  IProductData,
  IProductDataParams,
  IUpdateParamsItem
} from '@/service/production/yieldCorrection/type'

export default function useGuliaoSaleData(params: Ref<IProductDataParams>): [
  Ref<IProductData>,
  ComputedRef<
    {
      num: string
      desc: string
    }[]
  >,
  () => Promise<void>,
  (updateParams: IUpdateParamsItem[]) => Promise<string>
] {
  const productDayData = ref<IProductData>({
    deviceProList: [],
    totalPro: {
      main_run: '',
      main_pro: '',
      create_time: '',
      hour_main_pro: '',
      hour_stone_pro: '',
      electric: '',
      stone_pro: '',
      stone_run: '',
      id: 0,
      electric_ton: '',
      day_time: ''
    }
  })

  // 获取主机汇总数据
  const findProductDayDataAsync = async () => {
    const res = await findProductDayData(params.value)
    productDayData.value = res.data
  }

  const summaryData = computed(() => {
    return [
      { num: productDayData.value.totalPro.main_pro, desc: '主机产量/吨' },
      { num: productDayData.value.totalPro.stone_pro, desc: '制砂产量/吨' }
    ]
  })

  const updateProductDataAsync = async (updateParams: IUpdateParamsItem[]) => {
    const res = await updateProductData(updateParams)
    return res.data
  }

  return [
    productDayData,
    summaryData,
    findProductDayDataAsync,
    updateProductDataAsync
  ]
}
