import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  IDeviceGroup,
  IUpdateFocusParams,
  IUpdateFocus,
  IDetailParams,
  IDetailData,
  IDetailNewData,
  IDetailTemperData,
  IThreeTemperParams,
  IThreeTemperData,
  IOpenCloseData,
  IFacParams,
  IFacData,
  IDeviceGroupDataNew
} from './type'

enum DashboardApi {
  getDeviceGroup = '/deviceapp/deviceGroup',
  getDeviceGroupNew = '/deviceapp/deviceGroupNew',
  updateFocus = '/deviceapp/updateFocus',
  getDeviceInfo = '/deviceapp/deviceInfo',
  getNewClassReal = '/deviceapp/newClassReal',
  getDeviceTemper = '/deviceapp/deviceTemper',
  getThreeTemper = '/deviceapp/threeTemper',
  getOpenCloseMechineMore = '/deviceapp/openCloseMechineMore',
  getFac = '/deviceapp/deviceGroupDetail',
  getDeviceGroupMyFocusDetail = '/deviceapp/deviceGroupMyFocusDetail'
}

// 设备分组，关注的设备及类型
export function getDeviceGroup(): Promise<IRequestResult<IDeviceGroup>> {
  return hyRequest.post<IRequestResult<IDeviceGroup>>({
    url: DashboardApi.getDeviceGroup
  })
}

// 设备分组，关注的设备及类型 新接口
export function getDeviceGroupNew(): Promise<
  IRequestResult<IDeviceGroupDataNew>
> {
  return hyRequest.post<IRequestResult<IDeviceGroupDataNew>>({
    url: DashboardApi.getDeviceGroupNew
  })
}

export function getFac(data: IFacParams): Promise<IRequestResult<IFacData>> {
  return hyRequest.post<IRequestResult<IFacData>>({
    url: DashboardApi.getFac,
    data
  })
}

export function getDeviceGroupMyFocusDetail(): Promise<
  IRequestResult<IFacData>
> {
  return hyRequest.post<IRequestResult<IFacData>>({
    url: DashboardApi.getDeviceGroupMyFocusDetail
  })
}

//更新设备关注状态
export function updateFocus(
  data: IUpdateFocusParams
): Promise<IRequestResult<IUpdateFocus>> {
  return hyRequest.post<IRequestResult<IUpdateFocus>>({
    url: DashboardApi.updateFocus,
    data
  })
}

//设备实况固定信息
export function getDeviceInfo(
  data: IDetailParams
): Promise<IRequestResult<IDetailData>> {
  return hyRequest.post<IRequestResult<IDetailData>>({
    url: DashboardApi.getDeviceInfo,
    data
  })
}
//新设备实况
export function getNewClassReal(
  data: IDetailParams
): Promise<IRequestResult<IDetailNewData>> {
  return hyRequest.post<IRequestResult<IDetailNewData>>({
    url: DashboardApi.getNewClassReal,
    data
  })
}
//查询设备下所有电机，电机下所有变量
export function getDeviceTemper(
  data: IDetailParams
): Promise<IRequestResult<IDetailTemperData>> {
  return hyRequest.post<IRequestResult<IDetailTemperData>>({
    url: DashboardApi.getDeviceTemper,
    data
  })
}
//电流趋势
export function getThreeTemper(
  data: IThreeTemperParams
): Promise<IRequestResult<IThreeTemperData>> {
  return hyRequest.post<IRequestResult<IThreeTemperData>>({
    url: DashboardApi.getThreeTemper,
    data
  })
}
//查询最近3天开关机时间段
export function getOpenCloseMechineMore(
  data: IDetailParams
): Promise<IRequestResult<IOpenCloseData>> {
  return hyRequest.post<IRequestResult<IOpenCloseData>>({
    url: DashboardApi.getOpenCloseMechineMore,
    data
  })
}
