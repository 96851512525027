import { ref, Ref } from 'vue'
import dayjs from 'dayjs'
import {
  IProCloseDetailParams,
  IProCloseDetailListItem
} from '@/service/production/proReportAggregate/type'
import { getProCloseDetail } from '@/service/production/proReportAggregate'

export default function useCloseList(): [
  Ref<IProCloseDetailParams>,
  Ref<IProCloseDetailListItem[]>,
  () => Promise<void>
] {
  const formatDate = (date?: Date) => {
    return dayjs(date).format('YYYY-MM-DD')
  }

  const proCloseList = ref<IProCloseDetailListItem[]>([])
  const proCloseListParams: Ref<IProCloseDetailParams> = ref({
    areaId: '',
    startTime: formatDate() + ' 00:00:00',
    endTime: formatDate() + ' 23:59:59',
    deviceKey: '',
    deviceName: ''
  })
  // 查询时间范围内停机详情
  const getProCloseListAsync = async () => {
    const res = await getProCloseDetail(proCloseListParams.value)
    proCloseList.value = res.data.list
  }
  return [proCloseListParams, proCloseList, getProCloseListAsync]
}
