import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import { computed, defineComponent, ref, unref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import DeviceDetail from './cpns/DeviceDetail.vue';
import { useStore } from '@/store';
import { isEmpty } from './index';
export default defineComponent({
  name: 'VibrationEquipmentDetail',
  components: {
    DeviceDetail: DeviceDetail
  },
  setup: function setup() {
    var store = useStore();
    var route = useRoute();
    var router = useRouter();
    var _route$query = route.query,
      deviceId = _route$query.deviceId,
      deviceIndex = _route$query.deviceIndex,
      vrmWarnValue = _route$query.vrmWarnValue,
      currentWarnValue = _route$query.currentWarnValue;
    // 选中菜单下的设备列表
    var deviceList = computed(function () {
      return store.getters['vibrationEquipment/deviceGroupList'];
    });
    // 设备名
    var deviceName = computed(function () {
      var name = '';
      var findDevice = unref(deviceList).find(function (item) {
        return item.deviceId === Number(deviceId);
      });
      name = findDevice === null || findDevice === void 0 ? void 0 : findDevice.deviceName;
      return name;
    });
    var curDevice = ref({
      deviceName: '',
      deviceId: 0,
      index: 0
    });
    var preClick = function preClick() {
      var _curDevice$value;
      if (((_curDevice$value = curDevice.value) === null || _curDevice$value === void 0 ? void 0 : _curDevice$value.index) === 0) {
        _Toast('当前已经是第一条了');
        return;
      }
      if (curDevice.value) {
        --curDevice.value.index;
        curDevice.value.deviceName = unref(deviceList)[curDevice.value.index].deviceName;
        curDevice.value.deviceId = unref(deviceList)[curDevice.value.index].deviceId;
      }
    };
    var nextClick = function nextClick() {
      var _curDevice$value2;
      if (((_curDevice$value2 = curDevice.value) === null || _curDevice$value2 === void 0 ? void 0 : _curDevice$value2.index) === unref(deviceList).length - 1) {
        _Toast('没有下一条了');
        return;
      }
      if (curDevice.value) {
        ++curDevice.value.index;
        curDevice.value.deviceName = unref(deviceList)[curDevice.value.index].deviceName;
        curDevice.value.deviceId = unref(deviceList)[curDevice.value.index].deviceId;
      }
    };
    var goList = function goList() {
      router.push({
        name: 'VibrationEquipmentList'
      });
    };
    watch(deviceName, function (value) {
      if (isEmpty(deviceId) && isEmpty(deviceIndex) && isEmpty(value)) {
        curDevice.value = {
          deviceName: unref(deviceName),
          deviceId: Number(deviceId),
          index: Number(deviceIndex)
        };
      }
    }, {
      immediate: true
    });
    return {
      curDevice: curDevice,
      preClick: preClick,
      nextClick: nextClick,
      deviceName: deviceName,
      goList: goList,
      vrmWarnValue: vrmWarnValue,
      currentWarnValue: currentWarnValue
    };
  }
});