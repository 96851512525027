import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  IProductData,
  IProductDataParams,
  IProductUpdateLog,
  IUpdateParamsItem
} from './type'

enum DashboardApi {
  findProductDayData = '/product/findProductDayData',
  updateProductData = '/product/updateProductData',
  findProductUpdateLog = '/product/findProductUpdateLog'
}

//查询某天当前主机/制砂及各皮带秤产量
export function findProductDayData(
  data: IProductDataParams
): Promise<IRequestResult<IProductData>> {
  return hyRequest.post<IRequestResult<IProductData>>({
    url: DashboardApi.findProductDayData,
    data
  })
}
//修改生产产量
export function updateProductData(
  data: IUpdateParamsItem[]
): Promise<IRequestResult<string>> {
  return hyRequest.post<IRequestResult<string>>({
    url: DashboardApi.updateProductData,
    data
  })
}
//查询皮带秤修改记录
export function findProductUpdateLog(
  data: IProductDataParams
): Promise<IRequestResult<IProductUpdateLog>> {
  return hyRequest.post<IRequestResult<IProductUpdateLog>>({
    url: DashboardApi.findProductUpdateLog,
    data
  })
}
