import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'YieldSum',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    },
    isJump: {
      type: String,
      default: '0'
    },
    curDate: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var emptyStringFormat = useEmptyStringFormat();
    var router = useRouter();
    var toCheckDevice = function toCheckDevice() {
      router.push({
        name: 'ProEquipmentLive'
      });
    };
    var toYieldCorrection = function toYieldCorrection() {
      router.push({
        name: 'YieldCorrection',
        params: {
          date: props.curDate
        }
      });
    };
    return {
      emptyStringFormat: emptyStringFormat,
      toCheckDevice: toCheckDevice,
      toYieldCorrection: toYieldCorrection
    };
  }
});