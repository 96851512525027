import { ref, Ref } from 'vue'
import {
  IElectric,
  IProDataParams
} from '@/service/production/ProReportMortar/type'
import { getMortarElectric } from '@/service/production/ProReportMortar'

export default function useMortarElectirc(
  params: Ref<IProDataParams>
): [Ref<IElectric>, () => Promise<void>] {
  const mortarElectric = ref<IElectric>({
    totalSettleValue: 0,
    totalAveragePrice: 0,
    electric: 0,
    totalMoney: 0,
    tonEle: 0,
    totalSingular: 0
  })

  // 获取主机汇总数据
  const getMortarElectricAsync = async () => {
    const res = await getMortarElectric(params.value)

    mortarElectric.value = res.data
  }
  return [mortarElectric, getMortarElectricAsync]
}
