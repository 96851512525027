import HyRequest from '@/service';
// 任务详情
export function findTaskItemInfo(taskItemId: number, deviceId: number): Promise<any> {
  return HyRequest.get({
    url: `/eaInspectionItemInfoApp/findTaskItemInfo/?taskItemId=${taskItemId}&deviceId=${deviceId}`
  });
}
// 获取巡检任务
export function postPatrolScheme(params: {
  pageIndex: number;
  pageSize: number;
  t?: {
    assessorUserName?: string;
    inspectionTaskRuleId?: number;
    taskName?: string;
    useStatus?: number;
  };
}): Promise<any> {
  return HyRequest.post({
    url: `/h5/eaInspectionTask/selectByPage`,
    data: params
  });
}
// 删除巡检任务
export function delPatrolScheme(params: {
  inspectionTaskId: number;
  inspectionTaskRuleId: number;
}): Promise<any> {
  return HyRequest.delete({
    url: `/h5/eaInspectionTask/remove/${params.inspectionTaskId}/${params.inspectionTaskRuleId}`
  });
}
// 启用禁用状态
export function patrolSchemeStatus(params: {
  useStatus: number;
  inspectionTaskId: number;
  inspectionTaskRuleId: number;
}): Promise<any> {
  return HyRequest.post({
    url: `/h5/eaInspectionTask/changeUseStatus`,
    data: params
  });
}
// 获取审核人
export function findUsers(): Promise<any> {
  return HyRequest.get({
    url: `/deviceEaApp/findUsers`
  });
}
// 获取任务详情
export function getTaskDetail(params: {
  inspectionTaskId: number;
  inspectionTaskRuleId: number;
}): Promise<any> {
  return HyRequest.get({
    url: `/h5/eaInspectionTask/getInfo`,
    params: params
  });
}
