const isEmpty = (value: any) => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    JSON.stringify(value)
  ) {
    return true;
  }
  return false;
};

export { isEmpty };
