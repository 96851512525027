import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6924af07"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "equipment-live-detail"
};
var _hoisted_2 = {
  class: "title-box flex align-center"
};
var _hoisted_3 = {
  class: "title text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_icon = _resolveComponent("van-icon");
  var _component_device_detail = _resolveComponent("device-detail");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "left",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    function () {
      return _ctx.preClick && _ctx.preClick.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_van_icon, {
    name: "arrow-left",
    size: "14",
    color: "rgba(67, 114, 246, 1)"
  })]), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.curDevice.deviceName), 1), _createElementVNode("div", {
    class: "right",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    function () {
      return _ctx.nextClick && _ctx.nextClick.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_van_icon, {
    name: "arrow",
    size: "14",
    color: "rgba(67, 114, 246, 1)"
  })])]), _createVNode(_component_device_detail, {
    id: String(_ctx.curDevice.deviceId),
    deviceName: _ctx.curDevice.deviceName,
    vrmWarnValue: String(_ctx.vrmWarnValue),
    currentWarnValue: String(_ctx.currentWarnValue)
  }, null, 8, ["id", "deviceName", "vrmWarnValue", "currentWarnValue"])]);
}