import _toConsumableArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import DeviceListItem from './cpns/DeviceListItem.vue';
import { useStore } from '@/store';
import useFac from './hooks/useFac';
export default defineComponent({
  name: 'VibrationEquipmentList',
  components: {
    DeviceListItem: DeviceListItem,
    Loading: _Loading
  },
  setup: function setup() {
    var store = useStore();
    var _useFac = useFac(),
      _useFac2 = _slicedToArray(_useFac, 4),
      list2 = _useFac2[0],
      type = _useFac2[1],
      showLoading = _useFac2[2],
      getFacAsync = _useFac2[3];
    onMounted(function () {
      getFacAsync();
    });
    var listTemp = ref([]);
    watch(list2, function (newVal) {
      // 当list2变化，说明左侧菜单进行了切换，此时listTemp也要清空
      listTemp.value = [];
      if (newVal.length) {
        var _listTemp$value;
        (_listTemp$value = listTemp.value).push.apply(_listTemp$value, _toConsumableArray(newVal.slice(0, 2)));
      }
    });
    var handleScroll = function handleScroll(e) {
      // 获取内容高度
      var scrollH = document.documentElement.scrollHeight;
      // 获取窗口高度
      var innerH = window.innerHeight;
      // 滚动出去的高度
      var top = document.body.scrollTop || document.documentElement.scrollTop;
      // 当内容还剩余200的高度未滚动出的时候发送请求
      if (scrollH - top - innerH < 200) {
        // pageSize.value++
        if (!list2.value.length || list2.value.length && listTemp.value.length === list2.value.length) return;
        if (listTemp.value.length + 2 <= list2.value.length) {
          var _listTemp$value2;
          (_listTemp$value2 = listTemp.value).push.apply(_listTemp$value2, _toConsumableArray(list2.value.slice(listTemp.value.length, listTemp.value.length + 2)));
        } else {
          var _listTemp$value3;
          (_listTemp$value3 = listTemp.value).push.apply(_listTemp$value3, _toConsumableArray(list2.value.slice(listTemp.value.length, list2.value.length)));
        }
        console.log('scroll', e, scrollH - top - innerH < 200, listTemp.value);
      }
    };
    onMounted(function () {
      window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(function () {
      window.removeEventListener('scroll', handleScroll);
    });
    // 当前选中左侧菜单项下的设备列表
    var list = computed(function () {
      return store.getters['vibrationEquipment/deviceGroupList'];
    });
    // 更新设备的收藏和未收藏的状态
    var updateDevice = function updateDevice(deviceId, index) {
      var curItem = list.value.find(function (item) {
        return item.deviceId === deviceId;
      });
      var siderbarList = _toConsumableArray(store.state.vibrationEquipment.siderbar);
      // 点击了收藏，关注数量+1，取消了收藏，关注数量-1
      if (curItem) {
        if (curItem.isShow === 1) {
          curItem.isShow = 0;
          siderbarList[0].num--;
        } else {
          curItem.isShow = 1;
          siderbarList[0].num++;
        }
      }
      store.commit('vibrationEquipment/updateSiderbar', siderbarList);
      // 如果左侧菜单切换到了收藏菜单
      if (store.state.vibrationEquipment.curSiderbarIndex === 0) {
        // 关注列表中去除取消收藏的那一项
        list.value.splice(index, 1);
      }
    };
    return {
      list: list,
      list2: list2,
      type: type,
      updateDevice: updateDevice,
      showLoading: showLoading,
      listTemp: listTemp
    };
  }
});