import { ref, Ref } from 'vue'
import {
  ITrendDataItem,
  IProDataParams
} from '@/service/production/ProReportMortar/type'
import { getMortarElectricTrend } from '@/service/production/ProReportMortar'

export default function useProDataTrend(
  params: Ref<IProDataParams>
): [
  Ref<ITrendDataItem[]>,
  Ref<ITrendDataItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const mortarElectircTrend = ref<ITrendDataItem[]>([])
  const mortarElectircYearTrend = ref<ITrendDataItem[]>([])
  // 获取主机汇总数据
  const getMortarElectricTrendAsync = async () => {
    let num = 30

    switch (params.value.type) {
      // 日
      case 0:
        num = 30
        break
      // 周
      case 1:
        num = 30
        break
      // 月
      case 2:
        num = 12
        // num = 30
        break
      // 年
      case 3:
        num = 3
        // num = 12
        break
      // 自定义
      case 4:
        num = 10
        break
    }
    const res = await getMortarElectricTrend({
      ...params.value,
      rangeType: params.value.type,
      rangeNum: num
    })
    mortarElectircTrend.value = res.data.list
  }

  const getMortarElectricYearTrendAsync = async () => {
    const res = await getMortarElectricTrend({
      type: 3,
      date: params.value.date,
      rangeType: -1,
      rangeNum: 3
    })
    mortarElectircYearTrend.value = res.data.list
  }
  return [
    mortarElectircTrend,
    mortarElectircYearTrend,
    getMortarElectricTrendAsync,
    getMortarElectricYearTrendAsync
  ]
}
