import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { computed, defineComponent, ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import CDateTimeRangeSelect from '@/components/CDateTimeRangeSelect.vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import useProData from '../hooks/useProData';
import useMortarElectirc from '../hooks/useMortarElectirc';
import useMixture from '../hooks/useMixture';
import { PieEchart } from '@/components/page-echarts';
import useStatisticsData from '../hooks/useStatisticsData';
import hyRequest from '@/service';
export default defineComponent({
  name: 'CustomLog',
  components: {
    CDateTimeRangeSelect: CDateTimeRangeSelect,
    PieEchart: PieEchart
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    //当前日期区间
    var params = ref({
      date: dayjs().format('YYYY/MM/DD') + ',' + dayjs().format('YYYY/MM/DD'),
      type: 4
    });
    //获取砂浆产量汇总列表
    var mortarSumGroup = ref([]);
    function getMortarSumGroup() {
      hyRequest.post({
        url: '/product/mortarSumGroup',
        data: params.value
      }).then(function (res) {
        mortarSumGroup.value = res.data.list;
      });
    }
    var confirmDatetime = function confirmDatetime(payload) {
      params.value.date = payload.startTime + ',' + payload.endTime;
      lineParams.value.date = payload.startTime + ',' + payload.endTime;
      // 获取制砂总汇和配比
      getMortarProDataAsyc();
      //获取商砼砂浆部门的考勤数据
      // getAttendanceAsync()
      //获取耗电统计
      getMortarElectricAsync();
      //获取原材料配比
      getMortarMixtureAsync1();
      getMortarMixtureAsync2();
      //获取原材料采购统计
      getMortarReceiveStatisticsAsyc();
      //获取销售统计
      getMortarSaleStatisticsAsyc();
      getMortarSumGroup();
    };
    // 获取制砂总汇和制砂各分类总汇列表
    var _useProData = useProData(params),
      _useProData2 = _slicedToArray(_useProData, 3),
      sandData = _useProData2[0],
      receiveData = _useProData2[1],
      getMortarProDataAsyc = _useProData2[2];
    var sandDataTotal = computed(function () {
      return {
        num: sandData.value.totalSettleValue,
        desc: '砂浆产量/方'
      };
    });
    //获取耗电统计
    var _useMortarElectirc = useMortarElectirc(params),
      _useMortarElectirc2 = _slicedToArray(_useMortarElectirc, 2),
      mortarElectric = _useMortarElectirc2[0],
      getMortarElectricAsync = _useMortarElectirc2[1];
    var summaryDataShangTong = computed(function () {
      return [{
        num: mortarElectric.value.totalSettleValue,
        desc: '产量/方'
      }, {
        num: mortarElectric.value.electric,
        desc: '耗电量/度'
      }, {
        num: mortarElectric.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    //获取原材料采购和销售统计
    var _useStatisticsData = useStatisticsData(params),
      _useStatisticsData2 = _slicedToArray(_useStatisticsData, 4),
      receivingData = _useStatisticsData2[0],
      saleData = _useStatisticsData2[1],
      getMortarReceiveStatisticsAsyc = _useStatisticsData2[2],
      getMortarSaleStatisticsAsyc = _useStatisticsData2[3];
    onMounted(function () {
      // 获取制砂总汇
      getMortarProDataAsyc();
      //获取商砼砂浆部门的考勤数据
      // getAttendanceAsync()
      //获取耗电统计
      getMortarElectricAsync();
      //获取原材料配比
      getMortarMixtureAsync1();
      getMortarMixtureAsync2();
      //获取原材料采购统计
      getMortarReceiveStatisticsAsyc();
      //获取销售统计
      getMortarSaleStatisticsAsyc();
      getMortarSumGroup();
    });
    //获取原材料配比
    var lineParams = ref({
      date: dayjs().format('YYYY/MM/DD') + ',' + dayjs().format('YYYY/MM/DD'),
      type: 4
    });
    var _useMixture = useMixture(lineParams),
      _useMixture2 = _slicedToArray(_useMixture, 4),
      mixtureData1 = _useMixture2[0],
      mixtureData2 = _useMixture2[1],
      getMortarMixtureAsync1 = _useMixture2[2],
      getMortarMixtureAsync2 = _useMixture2[3];
    return {
      mixtureData1: mixtureData1,
      mixtureData2: mixtureData2,
      confirmDatetime: confirmDatetime,
      sandData: sandData,
      receiveData: receiveData,
      emptyStringFormat: emptyStringFormat,
      sandDataTotal: sandDataTotal,
      summaryDataShangTong: summaryDataShangTong,
      receivingData: receivingData,
      saleData: saleData,
      mortarSumGroup: mortarSumGroup
    };
  }
});