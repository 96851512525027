import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0b251fac"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "equipment-live"
};
var _hoisted_2 = {
  class: "siderbar"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "num"
};
var _hoisted_5 = {
  class: "text"
};
var _hoisted_6 = {
  class: "content",
  id: "contentWrap"
};
var _hoisted_7 = {
  key: 0,
  class: "loading"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.siderbar, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: item.groupName,
      class: _normalizeClass(["flex flex-column align-center justify-center siderbar-item", {
        active: _ctx.curSiderbarIndex === index
      }]),
      onClick: function onClick($event) {
        return _ctx.siderbarClick(index, item.groupNo);
      }
    }, [_createElementVNode("span", _hoisted_4, _toDisplayString(item.groupNumber), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(item.groupName), 1)], 10, _hoisted_3);
  }), 128))]), _createElementVNode("div", _hoisted_6, [_ctx.loading ? (_openBlock(), _createElementBlock("div", _hoisted_7, "加载中!")) : (_openBlock(), _createBlock(_component_router_view, {
    key: 1
  }))])]);
}