import { ref, Ref, computed, ComputedRef } from 'vue';
import { IDeviceGroupListItemNew } from '@/service/production/equipmentLive/type';
import { getDeviceGroupNew } from '@/service/production/equipmentLive';
import { useStore } from '@/store';
import localCache from '@/utils/localCache';

export default function useGoupListNew(): [
  Ref<IDeviceGroupListItemNew[]>,
  Ref<boolean>,
  () => Promise<void>
] {
  const store = useStore();
  const deviceGroup = ref<IDeviceGroupListItemNew[]>([]);
  const loading = ref(true);
  const curSiderbarIndex = computed<number>(() => {
    return store.state.production.curSiderbarIndex;
  });

  // 获取主机汇总数据
  const getDeviceGroupAsync = async () => {
    try {
      loading.value = true;
      const res = await getDeviceGroupNew();
      deviceGroup.value = res.data.list;

      //如果原先的选中菜单的id和现在的不一样，则说明切换厂区了,要注意考虑不同厂区的菜单列表长度不一致，原菜单当前选中索引有可能超现在的列表长度
      // 需要考虑切换后的厂区左侧菜单项的数据和切换前的厂区左侧菜单项的数量长度不同
      // 1.切换后的厂区左侧菜单项比切换前的厂区左侧菜单项的数量多
      // 2.切换后的厂区左侧菜单项比切换前的厂区左侧菜单项的数量少
      // if (
      //   store.state.production.siderbar.length !== res.data.list.length ||
      //   store.state.production.siderbar[curSiderbarIndex.value].groupNo !==
      //     res.data.list[curSiderbarIndex.value]?.groupNo
      // )

      if (
        !res.data.list
          .filter((item) => item.groupNo)
          .map((item) => item.groupNo)
          .includes(store.state.production.curSiderbarId)
      ) {
        // 有可能是切换厂区了，也有可能是没切换厂区，只是菜单列表被操作用在后台修改了（增删改）,设备列表当前选中菜单索引置零
        store.commit('production/updateSiderbarIndex', 0);
        store.commit('production/updateSiderbarId', undefined);
        // store.commit('production/updateSiderbar', [])
        store.commit('production/updateSiderbar', res.data.list);
      } else {
        // 没有切换厂区
        store.commit('production/updateSiderbar', res.data.list);
        store.commit(
          'production/updateSiderbarId',
          res.data.list[curSiderbarIndex.value].groupNo
        );
      }
      loading.value = false;
    } catch (error) {
      loading.value = false;
      console.log(error);
    } finally {
      console.log('finally');
    }
  };

  return [deviceGroup, loading, getDeviceGroupAsync];
}
