import { ref, Ref, computed } from 'vue';
import { findVrmDeviceClassify } from '@/service/vibrationEquipment';
import { IEquipmentListItemNew } from '@/service/vibrationEquipment/type';
import { useStore } from '@/store';

export default function useGoupListNew(): [
  Ref<IEquipmentListItemNew[]>,
  Ref<boolean>,
  () => Promise<void>
] {
  const store = useStore();
  const deviceGroup = ref<IEquipmentListItemNew[]>([]);
  const loading = ref(true);
  const curSiderbarIndex = computed<number>(() => {
    return store.state.vibrationEquipment.curSiderbarIndex;
  });

  // 获取设备振动汇总数据
  const getDeviceGroupAsync = async () => {
    try {
      loading.value = true;
      const res = await findVrmDeviceClassify();
      deviceGroup.value = res.data.list;
      console.log(
        store.state.vibrationEquipment.curSiderbarId,
        'store.state.vibrationEquipment.curSiderbarId'
      );
      if (
        !res.data.list
          .filter((item) => item.factoryId)
          .map((item) => item.factoryId)
          .includes(store.state.vibrationEquipment.curSiderbarId as any)
      ) {
        // 有可能是切换厂区了，也有可能是没切换厂区，只是菜单列表被操作用在后台修改了（增删改）,设备列表当前选中菜单索引置零
        store.commit('vibrationEquipment/updateSiderbarIndex', 0);
        store.commit('vibrationEquipment/updateSiderbarId', 0);
        store.commit('vibrationEquipment/updateSiderbar', res.data.list);
      } else {
        // 没有切换厂区
        store.commit('vibrationEquipment/updateSiderbar', res.data.list);
        store.commit(
          'vibrationEquipment/updateSiderbarId',
          res.data.list[curSiderbarIndex.value].factoryId
        );
      }
      loading.value = false;
    } catch (error) {
      loading.value = false;
      console.log(error);
    } finally {
      console.log('finally');
    }
  };

  return [deviceGroup, loading, getDeviceGroupAsync];
}
