import hyRequest from '@/service';
import { IRequestResult } from '@/service/types';
import { IEquipmentDataNew, IEquipmentDataInfoNew } from './type';

// 收藏设备
export function addVrmDeviceCollect(data: {
  factoryId: number;
  deviceId: number;
  vrmWarnValue: number;
  currentWarnValue: number;
}): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: '/vibCurrAnalyse/addVrmDeviceCollect',
    data
  });
}
// 编辑设备
export function editVrmDeviceCollect(data: {
  deviceId: number;
  vrmWarnValue: string;
  currentWarnValue: string;
}): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: '/vibCurrAnalyse/editVrmDeviceCollect',
    data
  });
}
// 取消收藏设备
export function delVrmDeviceCollect(data: {
  deviceId: number;
}): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: '/vibCurrAnalyse/delVrmDeviceCollect',
    data
  });
}
// 震动设备
export function getVibrationEquipment(
  factoryId: number,
  DEVICE_TYPE = 'Y'
): Promise<IRequestResult<IEquipmentDataInfoNew>> {
  return hyRequest.post<IRequestResult<IEquipmentDataInfoNew>>({
    url: '/vibCurrAnalyse/findVrmDevice',
    data: { DEVICE_TYPE, factoryId }
  });
}
// 震动设备分类
export function findVrmDeviceClassify(): Promise<
  IRequestResult<IEquipmentDataNew>
> {
  return hyRequest.post<IRequestResult<IEquipmentDataNew>>({
    url: '/vibCurrAnalyse/findVrmDeviceClassify'
  });
}
// 武汉制砂机震动与电流曲线
export function getCurrentCurve(data: {
  date: string;
  type: number;
  deviceId: number | string;
  factoryId: number | string;
}): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: '/vibCurrAnalyse/findVrm',
    data
  });
}
// 获取开机，关机时长
export function getOpenCloseTome(data: {
  date: string;
  type: number;
}): Promise<IRequestResult<any>> {
  return hyRequest.post<IRequestResult<any>>({
    url: '/vibCurrAnalyse/runTime',
    data
  });
}
