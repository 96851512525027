import { ref, Ref } from 'vue'
import {
  ITimeRange,
  ISandListItem
} from '@/service/production/proReportAggregate/type'
import { getSandData } from '@/service/production/proReportAggregate'

export default function useSandData(date: Ref<ITimeRange>): [
  Ref<{
    pro_num: number
    material_name: string
    status: string
    soil: any
  }>,
  Ref<ISandListItem[]>,
  Ref<{
    tonEle: string
    totalEle: string
  }>,
  () => Promise<void>
] {
  const sandData = ref({
    pro_num: 0,
    material_name: '',
    status: '',
    soil: {}
  })
  const sandEle = ref({
    tonEle: '',
    totalEle: ''
  })
  const sandListData = ref<ISandListItem[]>([])
  // 获取制砂总汇
  const getSandDataAsyc = async () => {
    const res = await getSandData(date.value)
    sandData.value = {
      material_name: res.data.stoneSum.material_name,
      pro_num: res.data.stoneSum.pro_num,
      status: res.data.status,
      soil: res.data.soil
    }
    sandListData.value = res.data.list
    sandEle.value.tonEle = res.data.stoneSum.tonEle
    sandEle.value.totalEle = res.data.stoneSum.totalEle
  }
  return [sandData, sandListData, sandEle, getSandDataAsyc]
}
