import { ref, Ref } from 'vue'
import {
  IMainData,
  ITimeRange
} from '@/service/production/proReportAggregate/type'
import { getMainData } from '@/service/production/proReportAggregate'

export default function useMainData(
  date: Ref<ITimeRange>
): [Ref<IMainData>, () => Promise<void>] {
  const mainData = ref<IMainData>({
    pro_num: 0,
    material_name: '',
    status: ''
  })

  // 获取主机汇总数据
  const getMainDataAsync = async () => {
    const res = await getMainData(date.value)
    mainData.value = res.data
  }
  return [mainData, getMainDataAsync]
}
