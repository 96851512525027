import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.push.js";
import { computed, defineComponent, onMounted } from 'vue';
import useGroupList from './hooks/useGoupListNew';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
export default defineComponent({
  name: 'ProdEquipmentLive',
  components: {},
  setup: function setup(props, ctx) {
    var store = useStore();
    var route = useRoute();
    var router = useRouter();
    var _useGroupList = useGroupList(),
      _useGroupList2 = _slicedToArray(_useGroupList, 3),
      siderbar = _useGroupList2[0],
      loading = _useGroupList2[1],
      getDeviceGroupAsync = _useGroupList2[2];
    onMounted(function () {
      // 获取左侧菜单列表
      getDeviceGroupAsync();
    });
    var curSiderbarIndex = computed(function () {
      return store.state.production.curSiderbarIndex;
    });
    var siderbarClick = function siderbarClick(index, id) {
      store.commit('production/updateSiderbarIndex', index);
      store.commit('production/updateSiderbarId', id);
      if (route.fullPath === '/proEquipmentLive/list') {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        return;
      }
      router.push({
        name: 'ProEquipmentLiveList'
      });
    };
    return {
      siderbar: siderbar,
      curSiderbarIndex: curSiderbarIndex,
      siderbarClick: siderbarClick,
      loading: loading
    };
  }
});