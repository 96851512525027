import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/img/productionLog/edit.png';
import _imports_1 from '@/assets/img/productionLog/arrow.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-315f0589"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "sumData"
};
var _hoisted_2 = {
  class: "flex flex-column"
};
var _hoisted_3 = {
  class: "num flex align-center"
};
var _hoisted_4 = {
  class: "desc"
};
var _hoisted_5 = {
  class: "status ml-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.emptyStringFormat(_ctx.data.num)), 1), _ctx.isJump === '1' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _imports_0,
    class: "edit ml-3",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    function () {
      return _ctx.toYieldCorrection && _ctx.toYieldCorrection.apply(_ctx, arguments);
    })
  })) : _createCommentVNode("", true)]), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.emptyStringFormat(_ctx.data.desc)), 1)]), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.data.status === '1' ? '运行' : '停机'), 1), _createElementVNode("img", {
    src: _imports_1,
    class: "icon",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    function () {
      return _ctx.toCheckDevice && _ctx.toCheckDevice.apply(_ctx, arguments);
    })
  })]);
}