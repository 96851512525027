import { ref, Ref } from 'vue'
import {
  IProDataTrendItem,
  IDateParams
} from '@/service/production/proReportAggregate/type'
import { getProDataTrend } from '@/service/production/proReportAggregate'

export default function useProDataTrend(
  params: Ref<IDateParams>
): [
  Ref<IProDataTrendItem[]>,
  Ref<IProDataTrendItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const proDataTrend = ref<IProDataTrendItem[]>([])
  const proDataYearTrend = ref<IProDataTrendItem[]>([])
  // 获取主机汇总数据
  const getProDataTrendAsync = async () => {
    let num = 30

    switch (params.value.type) {
      // 日
      case 0:
        num = 30
        break
      // 周
      case 1:
        num = 30
        break
      // 月
      case 2:
        num = 12
        // num = 30
        break
      // 年
      case 3:
        num = 3
        // num = 12
        break
      // 自定义
      case 4:
        num = 10
        break
    }
    const res = await getProDataTrend({
      ...params.value,
      rangeType: params.value.type,
      rangeNum: num
    })
    proDataTrend.value = res.data.list
  }

  const getProDataYearTrendAsync = async () => {
    const res = await getProDataTrend({
      type: 3,
      date: params.value.date,
      rangeType: -1,
      rangeNum: 3
    })
    proDataYearTrend.value = res.data.list
  }
  return [
    proDataTrend,
    proDataYearTrend,
    getProDataTrendAsync,
    getProDataYearTrendAsync
  ]
}
