import { ref, Ref } from 'vue'
import { getMonitorAccessToken } from '@/service/production/monitor'

export default function useMonitorAccessToken(): [
  Ref<string>,
  () => Promise<void>
] {
  const accessToken = ref('')
  // 获取主机汇总数据
  const getMonitorAccessTokenAsync = async () => {
    const res = await getMonitorAccessToken()
    accessToken.value = res.data
  }

  return [accessToken, getMonitorAccessTokenAsync]
}
