import { ref, Ref } from 'vue'
import {
  IDeviceAreaListItem,
  IDeviceAreaListParams,
  IOpenCloseDataListItem,
  IOpenCloseParams,
  ITimeRange
} from '@/service/production/proReportAggregate/type'
import {
  getDeviceAreaList,
  getOpenCloseList
} from '@/service/production/proReportAggregate'
import dayjs from 'dayjs'
export default function useDeviceAreaList(date: Ref<ITimeRange>): [
  Ref<{
    name: string
    list: IOpenCloseDataListItem[]
  }>,
  Ref<
    {
      name: string
      id: number
      list: IOpenCloseDataListItem[]
    }[]
  >,
  () => Promise<void>
] {
  const formatDate = (date?: Date) => {
    return dayjs(date).format('YYYY-MM-DD')
  }

  const mainBreakingList = ref<{
    name: string
    id: number
    list: IOpenCloseDataListItem[]
  }>({
    name: '',
    id: 0,
    list: []
  })
  const sandMakingList = ref<
    {
      name: string
      id: number
      list: IOpenCloseDataListItem[]
    }[]
  >([])

  // 获取主机汇总数据
  const getDeviceAreaListAsync = async () => {
    const res = await getDeviceAreaList({
      isMain: ''
    })

    res.data.list.forEach((item) => {
      if (item.is_main === '1') {
        //主机
        mainBreakingList.value.name = item.area_name
        mainBreakingList.value.id = item.id
      } else {
        //砂石骨料
        sandMakingList.value.push({
          name: item.area_name,
          id: item.id,
          list: []
        })
      }
      getOpenCloseListAsync({
        areaId: item.id,
        isMain: item.is_main,
        date: formatDate(new Date(date.value.startTime))
      })
    })
  }

  // 查询当天开停机列表
  const getOpenCloseListAsync = async (params: IOpenCloseParams) => {
    console.log('getOpenCloseListAsync', params)
    const res = await getOpenCloseList(params)
    if (params.isMain === '1') {
      mainBreakingList.value.list = res.data.list
    } else {
      const target = sandMakingList.value.find(
        (item) => item.id === params.areaId
      )
      if (target) {
        target.list = res.data.list
      }
    }
  }
  return [mainBreakingList, sandMakingList, getDeviceAreaListAsync]
}
