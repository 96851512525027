import hyRequest, { hyRequest1 } from '@/service'
import { IRequestResult } from '@/service/types'
import { IStock } from './type'

enum DashboardApi {
  getTotalData = '/report/report/spareCount',
  getRank = '/stockdata/stockStatus'
}

// 料位料价
export function getStockData(): Promise<IRequestResult<{ list: IStock[] }>> {
  return hyRequest.get({
    url: DashboardApi.getRank
  })
}

export function deviceWeightRatio() {
  return hyRequest.get<IRequestResult>({
    url: '/stockdata/deviceWeightRatio'
  })
}

export function saveWeightRatio(data: any) {
  return hyRequest.post<IRequestResult>({
    url: '/stockdata/saveWeightRatio',
    data
  })
}
//上面都是骨料数据请求
//商砼数据
export function getStockData1() {
  return hyRequest1.get<IRequestResult>({
    url: '/report/report/stoneRealtimeStockOfConcrete'
  })
}

//砂浆数据
export function getStockData2() {
  return hyRequest1.get<IRequestResult>({
    url: '/report/report/stoneRealtimeStockOfMortar'
  })
}
