export interface IStock {
  device_id: string
  device_type: EDeviceType
  device_type_id: string
  name: string
  price: string
  ratio: string
  stone_id: number
  stone_value: string
  weight: string
  weight_transfor_ratio: number
  safe_weight: number
  safeRatio: string
  safe_level: number
  usable_weight: string
}

export enum EDeviceType {
  gl, // 骨料
  st // 商砼
}
