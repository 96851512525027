import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { computed, defineComponent, ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import CDateTimeRangeSelect from '@/components/CDateTimeRangeSelect.vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import useProData from '../hooks/useProData';
import useShangTongElectirc from '../hooks/useShangTongElectirc';
import useMixture from '../hooks/useMixture';
import { PieEchart } from '@/components/page-echarts';
import useStatisticsData from '../hooks/useStatisticsData';
export default defineComponent({
  name: 'CustomLog',
  components: {
    CDateTimeRangeSelect: CDateTimeRangeSelect,
    PieEchart: PieEchart
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    //当前日期区间
    var params = ref({
      date: dayjs().format('YYYY/MM/DD') + ',' + dayjs().format('YYYY/MM/DD'),
      type: 4
    });
    var confirmDatetime = function confirmDatetime(payload) {
      params.value.date = payload.startTime + ',' + payload.endTime;
      // 获取商砼总汇和配比
      getCommercialProDataAsyc();
      //获取商砼砂浆部门的考勤数据
      // getAttendanceAsync()
      //获取商砼吨耗电数
      getCommercialElectricAsync();
      //获取商砼原材料配比
      getCommercialMixtureAsync1();
      getCommercialMixtureAsync2();
      //获取原材料采购统计
      getCommercialReceiveStatisticsAsyc();
      //获取销售统计
      getCommercialSaleStatisticsAsyc();
    };
    // 获取制砂总汇和制砂各分类总汇列表
    var _useProData = useProData(params),
      _useProData2 = _slicedToArray(_useProData, 3),
      shangTongData = _useProData2[0],
      receiveData = _useProData2[1],
      getCommercialProDataAsyc = _useProData2[2];
    var shangTongDataTotal = computed(function () {
      return {
        num: shangTongData.value.totalSettleValue,
        desc: '商砼产量/方'
      };
    });
    //获取商砼吨耗电数
    var _useShangTongElectirc = useShangTongElectirc(params),
      _useShangTongElectirc2 = _slicedToArray(_useShangTongElectirc, 2),
      commercialElectric = _useShangTongElectirc2[0],
      getCommercialElectricAsync = _useShangTongElectirc2[1];
    var summaryDataShangTong = computed(function () {
      return [{
        num: commercialElectric.value.totalSettleValue,
        desc: '产量/方'
      }, {
        num: commercialElectric.value.electric,
        desc: '耗电量/度'
      }, {
        num: commercialElectric.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    //获取原材料采购和销售统计
    var _useStatisticsData = useStatisticsData(params),
      _useStatisticsData2 = _slicedToArray(_useStatisticsData, 4),
      receivingData = _useStatisticsData2[0],
      saleData = _useStatisticsData2[1],
      getCommercialReceiveStatisticsAsyc = _useStatisticsData2[2],
      getCommercialSaleStatisticsAsyc = _useStatisticsData2[3];
    onMounted(function () {
      // 获取商砼总汇和配比
      getCommercialProDataAsyc();
      //获取商砼砂浆部门的考勤数据
      // getAttendanceAsync()
      //获取商砼吨耗电数
      getCommercialElectricAsync();
      //获取商砼原材料配比
      getCommercialMixtureAsync1();
      getCommercialMixtureAsync2();
      //获取原材料采购统计
      getCommercialReceiveStatisticsAsyc();
      //获取销售统计
      getCommercialSaleStatisticsAsyc();
    });
    var curLine = ref('总汇');
    var lineShow = ref(false);
    var lines = ref([{
      text: '汇总',
      value: 0
    }, {
      text: '一号线',
      value: 1
    }, {
      text: '二号线',
      value: 2
    }]);
    var _useMixture = useMixture(params),
      _useMixture2 = _slicedToArray(_useMixture, 4),
      mixtureData1 = _useMixture2[0],
      mixtureData2 = _useMixture2[1],
      getCommercialMixtureAsync1 = _useMixture2[2],
      getCommercialMixtureAsync2 = _useMixture2[3];
    var openLinePopup = function openLinePopup() {
      lineShow.value = true;
    };
    var onConfirmLine = function onConfirmLine(e) {
      lineShow.value = false;
      curLine.value = e.text;
      //重新请求数据
      //获取商砼原材料配比
      getCommercialMixtureAsync1();
      getCommercialMixtureAsync2();
    };
    var onCancelLine = function onCancelLine() {
      lineShow.value = false;
    };
    return {
      mixtureData1: mixtureData1,
      mixtureData2: mixtureData2,
      curLine: curLine,
      lineShow: lineShow,
      lines: lines,
      openLinePopup: openLinePopup,
      onCancelLine: onCancelLine,
      onConfirmLine: onConfirmLine,
      confirmDatetime: confirmDatetime,
      shangTongData: shangTongData,
      receiveData: receiveData,
      emptyStringFormat: emptyStringFormat,
      shangTongDataTotal: shangTongDataTotal,
      summaryDataShangTong: summaryDataShangTong,
      receivingData: receivingData,
      saleData: saleData
    };
  }
});