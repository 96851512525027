import { ref, Ref, computed, watch } from 'vue';
import { IEquipmentListItemInfoNew } from '@/service/vibrationEquipment/type';
import { getVibrationEquipment } from '@/service/vibrationEquipment';
import { useStore } from '@/store';

export default function useGuliaoList(): [
  Ref<IEquipmentListItemInfoNew[]>,
  Ref<string>,
  Ref<boolean>,
  () => Promise<void>
] {
  const store = useStore();
  const list2 = ref<IEquipmentListItemInfoNew[]>([]);
  const showLoading = ref(false);
  const type = ref('');
  const curSiderbarId = computed<number>(() => {
    return store.state.vibrationEquipment.curSiderbarId ?? 0;
  });

  // 获取主机汇总数据
  const getFacAsync = async () => {
    try {
      list2.value = [];
      showLoading.value = true;
      const res = await getVibrationEquipment(curSiderbarId.value);
      list2.value = [...res.data.list];
      store.commit('vibrationEquipment/updateDeviceGroup', res.data.list);
    } catch (error) {
      console.log(error);
    } finally {
      showLoading.value = false;
    }
  };

  // 监听左侧菜单发生改变，左侧菜单切换时，groupID会发生改变，需要去请求对应菜单的数据
  watch(curSiderbarId, () => {
    console.log('grounpId发生了改变', curSiderbarId);
    getFacAsync();
  });

  return [list2, type, showLoading, getFacAsync];
}
