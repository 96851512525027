import hyRequest from '@/service'
import { IRequestResult } from '@/service/types'
import {
  IProDataParams,
  IProData,
  IElectric,
  ITrendParams,
  ITrendDataItem,
  ITrendItem,
  IMixtureParams,
  IMixtureItem,
  ISaleStatisticsItem,
  IReceiveStatisticsData
} from './type'

enum DashboardApi {
  getCommercialProData = '/product/commercialProData',
  getCommercialElectric = '/product/commercialElectric',
  getCommercialElectricTrend = '/product/commercialElectricTrend',
  getCommercialReceiveTrend = '/product/commercialReceiveTrend',
  getCommercialMixture = '/product/commercialMixture',
  getCommercialReceiveStatistics = '/product/commercialReceiveStatistics',
  getCommercialSaleStatistics = '/product/commercialSaleStatistics'
}

// 砂浆产量及使用量统计
export function getCommercialProData(
  data: IProDataParams
): Promise<IRequestResult<IProData>> {
  return hyRequest.post<IRequestResult<IProData>>({
    url: DashboardApi.getCommercialProData,
    data
  })
}

//商砼耗电量统计
export function getCommercialElectric(
  data: IProDataParams
): Promise<IRequestResult<IElectric>> {
  return hyRequest.post<IRequestResult<IElectric>>({
    url: DashboardApi.getCommercialElectric,
    data
  })
}

// 商砼耗电量趋势图
export function getCommercialElectricTrend(
  data: ITrendParams
): Promise<IRequestResult<{ list: ITrendDataItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: ITrendDataItem[] }>>({
    url: DashboardApi.getCommercialElectricTrend,
    data
  })
}

//商砼原材料消耗趋势
export function getCommercialReceiveTrend(
  data: ITrendParams
): Promise<IRequestResult<{ list: ITrendItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: ITrendItem[] }>>({
    url: DashboardApi.getCommercialReceiveTrend,
    data
  })
}

//商砼配比
export function getCommercialMixture(
  data: IMixtureParams
): Promise<IRequestResult<{ list: IMixtureItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: IMixtureItem[] }>>({
    url: DashboardApi.getCommercialMixture,
    data
  })
}

//商砼原材料采购统计

export function getCommercialReceiveStatistics(
  data: IProDataParams
): Promise<IRequestResult<IReceiveStatisticsData>> {
  return hyRequest.post<IRequestResult<IReceiveStatisticsData>>({
    url: DashboardApi.getCommercialReceiveStatistics,
    data
  })
}

//商砼销售统计
export function getCommercialSaleStatistics(
  data: IProDataParams
): Promise<IRequestResult<{ list: ISaleStatisticsItem[] }>> {
  return hyRequest.post<IRequestResult<{ list: ISaleStatisticsItem[] }>>({
    url: DashboardApi.getCommercialSaleStatistics,
    data
  })
}
