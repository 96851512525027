import { ref, Ref, computed } from 'vue'
import { useStore } from '@/store'
import {
  IDetailData,
  IDetailParams,
  IDetailNewListItem,
  IDetailTemperListItem,
  IThreeTemperListItem,
  IThreeTemperParams,
  IMachiVariableItem,
  IOpenCloseListItem
} from '@/service/production/equipmentLive/type'
import {
  getDeviceInfo,
  getNewClassReal,
  getDeviceTemper,
  getThreeTemper,
  getOpenCloseMechineMore
} from '@/service/production/equipmentLive'

export default function useGuliaoList(
  params: Ref<IDetailParams>,
  threeTempeParams: Ref<IThreeTemperParams>
): [
  Ref<IDetailData>,
  Ref<IDetailNewListItem[]>,
  Ref<IDetailTemperListItem[]>,
  Ref<IThreeTemperListItem[]>,
  Ref<IOpenCloseListItem[]>,
  () => Promise<void>,
  () => Promise<void>,
  () => Promise<IMachiVariableItem>,
  () => Promise<void>,
  () => Promise<void>
] {
  const store = useStore()
  const deviceInfo = ref<IDetailData>({
    measure: '',
    rated_power: '',
    release_date: '',
    use: [],
    rated_current: '',
    weight: 0,
    draw: [],
    rated_voltage: '',
    pro_date: ''
  })
  const newClassReal = ref<IDetailNewListItem[]>([])
  const deviceTemper = ref<IDetailTemperListItem[]>([])
  const threeTemper = ref<IThreeTemperListItem[]>([])
  const openCloseList = ref<IOpenCloseListItem[]>([])

  const getDeviceInfoAsync = async () => {
    const res = await getDeviceInfo(params.value)
    deviceInfo.value = res.data
    store.commit('production/updateDrawList', res.data.draw)
    store.commit('production/updateUseList', res.data.use)
  }
  const getNewClassRealAsync = async () => {
    const res = await getNewClassReal(params.value)
    newClassReal.value = res.data.list[0].list
  }
  const getDeviceTemperAsync = async () => {
    const res = await getDeviceTemper(params.value)
    deviceTemper.value = res.data.list
    return deviceTemper.value[0].machiVariable[0]
  }
  const getThreeTemperAsync = async () => {
    const res = await getThreeTemper(threeTempeParams.value)
    threeTemper.value = res.data.list
  }
  const getOpenCloseMechineMoreAsync = async () => {
    const res = await getOpenCloseMechineMore(params.value)
    openCloseList.value = res.data.list.map((item) => {
      item.runTimeList = item.runTimeList.filter((iten) => iten.value !== '0')
      return item
    })
  }

  return [
    deviceInfo,
    newClassReal,
    deviceTemper,
    threeTemper,
    openCloseList,
    getDeviceInfoAsync,
    getNewClassRealAsync,
    getDeviceTemperAsync,
    getThreeTemperAsync,
    getOpenCloseMechineMoreAsync
  ]
}
