import { IProData } from './type';
import hyRequest from '@/service';
import { IRequestResult } from '@/service/types';

enum DashboardApi {
  getFactorySaleStoneData = '/saleData/factorySaleStoneData'
}

// 物料销售排行
export function getFactorySaleStoneData(): Promise<IRequestResult<IProData>> {
  return hyRequest.post<IRequestResult<IProData>>({
    data: '{}',
    url: DashboardApi.getFactorySaleStoneData,
    headers: { 'Content-Type': 'application/json' }
  });
}
