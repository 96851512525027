import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  ISaleStatisticsItem,
  IProDataParams,
  IReceiveStatisticsData
} from '@/service/production/ProReportMortar/type'
import {
  getMortarSaleStatistics,
  getMortarReceiveStatistics
} from '@/service/production/ProReportMortar'

export default function useProData(
  params: Ref<IProDataParams>
): [
  Ref<IReceiveStatisticsData>,
  Ref<ISaleStatisticsItem[]>,
  () => Promise<void>,
  () => Promise<void>
] {
  const receivingData = ref<IReceiveStatisticsData>({
    list: [],
    name: '',
    sumTotal: 0,
    sumWeight: 0
  })
  const saleData = ref<ISaleStatisticsItem[]>([])
  // 获取砂浆原材料采购统计
  const getMortarReceiveStatisticsAsyc = async () => {
    const res = await getMortarReceiveStatistics(params.value)
    receivingData.value = res.data
  }
  //获取砂浆销售统计
  const getMortarSaleStatisticsAsyc = async () => {
    const res = await getMortarSaleStatistics(params.value)
    saleData.value = res.data.list
  }

  return [
    receivingData,
    saleData,
    getMortarReceiveStatisticsAsyc,
    getMortarSaleStatisticsAsyc
  ]
}
