import { ref, Ref, computed, ComputedRef } from 'vue'
import { findProductUpdateLog } from '@/service/production/yieldCorrection'
import {
  IProductDataParams,
  IProductUpdateLogItem
} from '@/service/production/yieldCorrection/type'

export default function useGuliaoSaleData(
  params: Ref<IProductDataParams>
): [Ref<IProductUpdateLogItem[]>, () => Promise<void>] {
  const productUpdateLog = ref<IProductUpdateLogItem[]>([])

  const findProductUpdateLogAsync = async () => {
    const res = await findProductUpdateLog(params.value)
    productUpdateLog.value = res.data.list
  }

  return [productUpdateLog, findProductUpdateLogAsync]
}
