import _objectSpread from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _slicedToArray from "D:/code/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { computed, defineComponent, ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import CDateTimeRangeSelect from '@/components/CDateTimeRangeSelect.vue';
import { useEmptyStringFormat } from '@/hooks/fn';
import useMainData from '../hooks/useMainData';
import useSandData from '../hooks/useSandData';
import YieldSum from './YieldSum.vue';
import hyRequest from '@/service';
export default defineComponent({
  name: 'CustomLog',
  components: {
    CDateTimeRangeSelect: CDateTimeRangeSelect,
    YieldSum: YieldSum
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    // const formatDate = (date?: Date) => {
    //   return dayjs(date).format('YYYY/MM/DD')
    // }
    var confirmDatetime = function confirmDatetime(payload) {
      date.value = {
        startTime: payload.startTime + ' 00:00:00',
        endTime: payload.endTime + ' 23:59:59'
      };
      // 获取主机汇总数据
      getMainDataAsync();
      // 获取制砂总汇
      getSandDataAsyc();
      getWaterData(date);
    };
    var date = ref({
      startTime: dayjs().format('YYYY-MM-DD') + ' 00:00:00',
      endTime: dayjs().format('YYYY-MM-DD') + ' 23:59:59'
    });
    // 获取主破总汇
    var _useMainData = useMainData(date),
      _useMainData2 = _slicedToArray(_useMainData, 2),
      mainData = _useMainData2[0],
      getMainDataAsync = _useMainData2[1];
    var mainDataTotal = computed(function () {
      return {
        num: mainData.value.pro_num,
        desc: mainData.value.material_name,
        status: mainData.value.status
      };
    });
    // 获取制砂总汇和制砂各分类总汇列表
    var _useSandData = useSandData(date),
      _useSandData2 = _slicedToArray(_useSandData, 4),
      sandData = _useSandData2[0],
      sandListData = _useSandData2[1],
      sandEle = _useSandData2[2],
      getSandDataAsyc = _useSandData2[3];
    var sandDataTotal = computed(function () {
      return {
        num: sandData.value.pro_num,
        desc: sandData.value.material_name,
        status: sandData.value.status
      };
    });
    var sandEleTotal = computed(function () {
      return [{
        num: sandEle.value.totalEle,
        desc: '耗电量/度'
      }, {
        num: sandEle.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    var factoryID = ref(0);
    onMounted(function () {
      hyRequest.post({
        url: '/factoryapp/getFactoryInfo'
      }).then(function (res) {
        console.log(res.data.factory);
        factoryID.value = res.data.factory;
      });
      // 获取主机汇总数据
      getMainDataAsync();
      // 获取制砂总汇
      getSandDataAsyc();
      getWaterData(date);
    });
    var getWaterData = function getWaterData(data) {
      hyRequest.post({
        url: '/product/getWashedSandData',
        data: _objectSpread(_objectSpread({}, data.value), {}, {
          areaId: '121',
          formulaType: 'wash_line'
        })
      }).then(function (res) {
        waterData.value = res.data;
      });
    };
    var waterData = ref({
      status: '1',
      washedSand: {
        pro_num: '0',
        material_name: ''
      },
      totalEle: '0',
      tonEle: '0'
    });
    var waterSandEleTotal = computed(function () {
      return [{
        num: sandEle.value.totalEle,
        desc: '耗电量/度'
      }, {
        num: sandEle.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    var waterTotal = computed(function () {
      return [{
        num: waterData.value.totalEle,
        desc: '耗电量/度'
      }, {
        num: waterData.value.tonEle,
        desc: '吨耗电/度'
      }];
    });
    var waterDataTotal = computed(function () {
      var tmp = dayjs(date.value.endTime).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-DD');
      return {
        num: waterData.value.washedSand.pro_num,
        desc: tmp ? '产量/吨 (00:00 ~ 目前)' : '产量/吨 (00:00 ~ 24:00)',
        status: waterData.value.status + ''
      };
    });
    return {
      confirmDatetime: confirmDatetime,
      mainData: mainData,
      sandData: sandData,
      sandListData: sandListData,
      emptyStringFormat: emptyStringFormat,
      mainDataTotal: mainDataTotal,
      sandDataTotal: sandDataTotal,
      sandEleTotal: sandEleTotal,
      waterSandEleTotal: waterSandEleTotal,
      waterData: waterData,
      waterTotal: waterTotal,
      waterDataTotal: waterDataTotal,
      factoryID: factoryID
    };
  }
});