import { ref, Ref } from 'vue'
import { getStockTrend } from '@/service/production/monitor'
import {
  IStockTrendParams,
  IStockTrendItem
} from '@/service/production/monitor/type'

export default function useStockTrend(
  params: Ref<IStockTrendParams>
): [Ref<IStockTrendItem[]>, () => Promise<void>] {
  const stockTrendData = ref<IStockTrendItem[]>([])
  // 获取主机汇总数据
  const getStockTrendAsync = async () => {
    const res = await getStockTrend(params.value)
    stockTrendData.value = res.data.list
  }

  return [stockTrendData, getStockTrendAsync]
}
