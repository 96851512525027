import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';
export default defineComponent({
  name: 'Drawings',
  components: {},
  setup: function setup(props, ctx) {
    var store = useStore();
    var drawList = computed(function () {
      return store.state.production.drawList;
    });
    var itemClick = function itemClick(value) {
      window.open(value.link, '__bank');
      // item.value = value
      // show.value = true
    };
    return {
      drawList: drawList,
      itemClick: itemClick
    };
  }
});