import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  IProDataParams,
  IMixtureItem
} from '@/service/production/ProReportMortar/type'
import { getMortarMixture } from '@/service/production/ProReportMortar'

export default function useMixture(params: Ref<IProDataParams>): [
  ComputedRef<{
    pieData: {
      name: string
      value: number
    }[]
    unit: string
    customTooltip: boolean
    tooltipFormatter: (params: any) => string
  }>,
  ComputedRef<{
    pieData: {
      name: string
      value: number
    }[]
    unit: string
    customTooltip: boolean
    tooltipFormatter: (params: any) => string
  }>,
  () => Promise<void>,
  () => Promise<void>
] {
  const list1 = ref<IMixtureItem[]>([])
  const list2 = ref<IMixtureItem[]>([])
  // 获取制砂总汇
  const getMortarMixtureAsync1 = async () => {
    const res = await getMortarMixture({
      ...params.value,
      lineNum: 1
    })
    list1.value = res.data.list
  }
  const getMortarMixtureAsync2 = async () => {
    const res = await getMortarMixture({
      ...params.value,
      lineNum: 2
    })
    list2.value = res.data.list
  }
  const mixtureData1 = computed(() => {
    return {
      pieData: list1.value
        .map((item) => {
          return {
            name: item.stone_name,
            value: item.net_weight
          }
        })
        .filter((item) => item.value),
      unit: 'kg',
      customTooltip: true,
      tooltipFormatter: (params: any) => {
        return `
          <div >
            <div class="flex align-start">
              <span class="mt-1 mr-1">${params.marker}</span>
              <div>
                <span style="font-size: 12px;">${params.name}</span>
                <div>
                 <span style="font-size: 12px;">${params.value}kg</span>
                </div>
              </div>
            </div>
          </div>
          `
      }
    }
  })
  const mixtureData2 = computed(() => {
    return {
      pieData: list2.value
        .map((item) => {
          return {
            name: item.stone_name,
            value: item.net_weight
          }
        })
        .filter((item) => item.value),
      unit: 'kg',
      customTooltip: true,
      tooltipFormatter: (params: any) => {
        return `
          <div >
            <div class="flex align-start">
              <span class="mt-1 mr-1">${params.marker}</span>
              <div>
                <span style="font-size: 12px;">${params.name}</span>
                <div>
                 <span style="font-size: 12px;">${params.value}kg</span>
                </div>
              </div>
            </div>
          </div>
          `
      }
    }
  })
  return [
    mixtureData1,
    mixtureData2,
    getMortarMixtureAsync1,
    getMortarMixtureAsync2
  ]
}
