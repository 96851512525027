import { ref, Ref } from 'vue'
import {
  IElectric,
  IProDataParams
} from '@/service/production/ProReportShangTong/type'
import { getCommercialElectric } from '@/service/production/ProReportShangTong'

export default function useCommercialElectirc(
  params: Ref<IProDataParams>
): [Ref<IElectric>, () => Promise<void>] {
  const commercialElectric = ref<IElectric>({
    totalSettleValue: 0,
    totalAveragePrice: 0,
    electric: 0,
    totalMoney: 0,
    tonEle: 0,
    totalSingular: 0
  })

  // 获取主机汇总数据
  const getCommercialElectricAsync = async () => {
    const res = await getCommercialElectric(params.value)

    commercialElectric.value = res.data
  }
  return [commercialElectric, getCommercialElectricAsync]
}
